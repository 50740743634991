import dealActionTypes from './deals.types';

const INITIAL_STATE = {
    deals: [],
    futureVolumes: [],
    buyPieData: [],
    sellPieData:[],
    isFetching: false,
    error: null,
};

const dealsReducer = (state= INITIAL_STATE, action) => {
    switch(action.type) {
        case dealActionTypes.GET_DEALS_START:
            return {
                ...state,
                isFetching: true
            }
        case dealActionTypes.GET_DEALS_SUCCESS:
            return {
                ...state,
                deals: [...action.payload],
                isFetching: false,
            }
        case dealActionTypes.GET_DEALS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case dealActionTypes.CLEAR_DEALS_STATE:
            return {
                ...state,
                deals: [],
                futureVolumes: [],
                buyPieData: [],
                sellPieData: [],
                isFetching: false,
                error: null
            }
        case dealActionTypes.GET_FUTURE_VOLUMES_START: 
            return {
                ...state,
                isFetching: true
            }
        case dealActionTypes.GET_FUTURE_VOLUMES_SUCCESS:
            return {
                ...state,
                futureVolumes: [...action.payload.barsData],
                buyPieData: [...action.payload.buyData],
                sellPieData: [...action.payload.sellData],
                isFetching: false
            }
        case dealActionTypes.GET_FUTURE_VOLUMES_FAILURE:
            return {    
                ...state,
                isFetching: false,
                error: action.payload
            }
        case dealActionTypes.CLEAR_FUTURE_VOLUMES:
            return {
                ...state,
                futureVolumes: []
            }   
        case dealActionTypes.CLEAR_DEALS_ERROR: 
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
};

export default dealsReducer;