import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';


import {Container, P} from './footer.styles';


const Footer = () => {
    
    const year = new Date().getFullYear();

    return (
        <Container>
        <P >© {year} All rights reserved to <a href="https://www.prico.com" target="_blank" rel="noreferrer">PRICO</a> Risk Management and Counseling</P>
        <P >Contact Us: B.S.R Building 2,
        1 Ben Gurion St., Bnei Brak, Israel.
        Mail:<span><a href="mailto:info@prico.com">info@prico.com</a></span>
        Phone:<span><a href="tel:+97236167070">+972-3-6167070</a></span>
        or :
        <a href="https://www.facebook.com/PRICOGroup" target="_blank" rel="noreferrer"><FontAwesomeIcon style={{fontSize: '14px', color: '#4267B2'}} icon={faFacebook} /></a>
        <a href="https://www.linkedin.com/company/---prico-group/" target="_blank" rel="noreferrer"><FontAwesomeIcon style={{fontSize: '14px', color: '#0e76a8'}} icon={faLinkedin} /></a>
        <a href="https://wa.me/972509725000" target="_blank" rel="noreferrer">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/256px-WhatsApp.svg.png" alt="whatsapp logo" width="14px" height="14px"/>
        </a>
        </P>

        </Container>
    )
};


export default Footer;