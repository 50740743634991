import { createSelector } from 'reselect';

const selectPostsParent = state => state.posts;

export const selectPosts = createSelector(
    [selectPostsParent],
    posts => posts.posts
);

export const selectPostsIsFetching = createSelector(
    [selectPostsParent],
    posts => posts.isFetching
);

export const selectPostsError = createSelector(
    [selectPostsParent],
    posts => posts.error
);

export const selectCurrentPost = createSelector(
    [selectPostsParent],
    posts => posts.currentPost
);