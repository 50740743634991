import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import rootReducer from './root-reducer';

const middleWares = [];

if(process.env.NODE_ENV=== 'development'){
    middleWares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(thunk, ...middleWares));

export const persistor = persistStore(store);

export default store;
