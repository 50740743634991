import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'

import { selectCurrentUser } from '../redux/user/user.selectors';
import { Route , Redirect} from 'react-router-dom';
// import Header from '../components/header/header.component';



export const PublicRoute = ({ currentUser, component:Component,...rest})=>(
    <Route {...rest} component = {(props)=>(
        currentUser ? (
            
            <Redirect to="/dashboard"/> 
            
        ) : (
            <div>
                <Component {...props}/>
            </div>
            ) 
    )}/>
);

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
})

export default connect(mapStateToProps)(PublicRoute);