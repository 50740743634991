import './utility.styles.scss';

export const NotFoundPage = () => (
    <div className='not-found-page'>
        <h1> אופס הדף שחיפשת לא נמצא....</h1>
    </div>
)

export const HelpPage = () => (
    <div className='help-page'>
        <h1> דף עזרה </h1>
    </div>
)