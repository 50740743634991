import postsActionTypes from './posts.types';

const INITIAL_STATE = {
    posts: [],
    isFetching: false,
    error: null
};

const postsReducer = (state = INITIAL_STATE, action)=> {
    switch(action.type) {
        case postsActionTypes.GET_POSTS_START:
            return {
                ...state,
                isFetching: true
            }
        case postsActionTypes.GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: [...action.payload],
                isFetching: false,
                error: null
            }
        case postsActionTypes.GET_POSTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case postsActionTypes.CLEAR_POSTS_STATE:
            return {
                ...state,
                posts: [],
            }
        default:
            return state;
    };
};

export default postsReducer;
