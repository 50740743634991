import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession  from 'redux-persist/lib/storage/session';

import userReducer from './user/user.reducer';
import dealsReducer from './deals/deals.reducer';
import postsReducer from './posts/posts.reducer';
import articlesReducer from './finance-info/finance-info.reducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist:['user']
};

const rootReducer = combineReducers({
    user:  userReducer,
    deals: dealsReducer,
    posts: postsReducer,
    articles: articlesReducer
});

export default persistReducer(persistConfig,rootReducer);

