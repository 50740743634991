import React from 'react';
import { connect } from 'react-redux';
import { Route , Redirect} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { selectCurrentUser } from '../redux/user/user.selectors'
// import Header from '../components/header/header.component';

export const PrivateRoute = ({ currentUser, component:Component,...rest}) => (
    <Route {...rest} component = {(props)=>(
        currentUser ? 
           (
            <div>
                <Component {...props}/>
            </div>
        ) : (
            <Redirect to="/"/>    ) 
    )}/>
);

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser
});

export default connect(mapStateToProps)(PrivateRoute);