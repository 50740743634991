import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {  createStructuredSelector } from 'reselect';
import { faBars} from '@fortawesome/free-solid-svg-icons';

import { HeaderContainer , 
        Image, 
        OptionsDiv, 
        UserIconDiv, 
        Rotate, 
        UnRotate,
        FontAwesomeIconStyled,
        FontAwesomeIconStyled2,
        HeaderDiv,
        CustomerTitle,
        CustomerTitleLine,
        TitleItem,
         } from './header.styles';
import DropdownMenu from '../dropdown-menu/dropdown-menu.component';
import logo from './pricoLogoWhite.svg';
import Ticker from '../news-ticker/news-ticker.component';
import NavBar from '../navbar/navbar.component';
import SelectControl, {SelectLocal} from '../select/select.component';

import { selectCurrentUser, selectCurrentCustomer, selectGroupCustomers  } from '../../redux/user/user.selectors';
import { signoutStart, handleGetGroupCustomers, setCurrentCustomer } from '../../redux/user/user.actions';
import { clearPostsState } from '../../redux/posts/posts.actions';
import { clearDealsState} from '../../redux/deals/deals.actions';




const Header = (props) => {
    const { 
        currentUser, 
        signoutStart, 
        clearPosts, 
        clearDeals, 
        handleGetGroupCustomers, 
        handleSetCurrentCustomer,
        currentCustomer,
        groupCustomers,
    } = props;
    const customerRef = useRef(null);

    const [customer,setCustomer] = useState(null);

    const handleSelectCustomer = (option) => {
        if(!currentUser.IsAdmin){
            setCustomer({...option});
            handleSetCurrentCustomer({id:option.key, name: option.label})
        }
        if(currentUser.IsAdmin){
            setCustomer({...option});
            option && handleSetCurrentCustomer({id: option.value, name: option.label});
        }
    };
    
    const [ showMenu, setShowMenu ] = useState(false)
    const { ChangePassword } = currentUser || false;
    
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState(null);
    
    const handleSelectOption = (path) => {
        setCurrentPath(path);
        if(path) {
            history.push(path);
        }
    };
    
    const handleShowMenu = useCallback(() => {
        setShowMenu(!showMenu)
    },[showMenu]);
    
    const handleSignout = ()=> {
        signoutStart(currentUser.UserID);
        clearDeals();
        clearPosts();
    }
    
    useEffect(()=> {
        if(currentUser) {
            if(ChangePassword === true) {
                handleSelectOption('/profile/password')
                history.push('/profile/password')
            }else{
                if(currentUser.isGroupCustomer && !currentUser.IsAdmin) {
                    handleGetGroupCustomers(currentUser.CustomerNumber);
                    setCustomer({key: currentUser.CustomerNumber, label:currentUser.CustomerName});
                    customerRef.current.select.setValue({key: currentUser.CustomerNumber, label: currentUser.CustomerName});
                }
                if(currentUser.IsAdmin){
                    setCustomer({key: currentUser.CustomerNumber, label:currentUser.CustomerName});
                    // customerRef.current.select.setValue({key: currentUser.CustomerNumber, label: currentUser.CustomerName});
                }
                handleSelectOption('/dashboard')
            }

            
        }
        if(!currentUser) {
            handleSelectOption(null)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser,ChangePassword]);

    
    useEffect(()=>{
        if(showMenu) {
            window.addEventListener('click', handleShowMenu)
        } 
        return ( )=> {
            if(showMenu) {
                window.removeEventListener('click', handleShowMenu)
            }
        }
    },[showMenu,handleShowMenu])
    
    useEffect(()=> {
        const persistedShowTicker = localStorage.getItem('showNews') !== null ? localStorage.getItem('showNews') : '';
        const persistedFiltersPanelOpen = localStorage.getItem('isPanelOpen') !== null ? localStorage.getItem('isPanelOpen') : '';
        
        if(!persistedShowTicker) {
            localStorage.setItem('showNews', JSON.stringify({showTicker: true}));
        };
        if(!persistedFiltersPanelOpen) {
            localStorage.setItem('isPanelOpen', JSON.stringify({isPanelOpen: true}));
        }
    },[]);

    // const customer = {key: currentCustomer.id, label: currentCustomer.name};
    
    const Options = () => (
        <div style={{width: '100%'}}>
        <OptionsDiv>
        <NavBar currentPath={currentPath} 
                handleSelectOption={handleSelectOption} 
                handleSignout={handleSignout} 
                ChangePassword={ChangePassword}
                isAdmin={currentUser.IsAdmin}
                groupCustomers={groupCustomers}
                handleSetCurrentCustomer={handleSetCurrentCustomer}
                currentCustomer={currentCustomer}
                />

        </OptionsDiv>
        </div>

    );

    return (
        <HeaderContainer>
        <Link to='/'><Image src={logo} /></Link>
        {
            currentUser && currentCustomer && (
                <>
                <HeaderDiv>
                <CustomerTitle>
                <CustomerTitleLine><TitleItem fixed fweight="bolder">User :</TitleItem><div style={{width: '5px'}}/><TitleItem flex={2} color="lightSalmon">{currentUser.FirstName}</TitleItem></CustomerTitleLine>
                <CustomerTitleLine><TitleItem  fixed fweight="bolder">Customer :</TitleItem><div style={{width: '5px'}}/>
                <TitleItem flex={2} color="lightSalmon">
                {
                    !currentUser.IsAdmin && !currentUser.isGroupCustomer && currentCustomer.name
                }
                {
                    !currentUser.IsAdmin && currentUser.isGroupCustomer && 
                    <SelectLocal
                    label='Customer'
                    options={groupCustomers}
                    optionValue={customer} 
                    setOptionValue={handleSelectCustomer}
                    hiddenLabel
                    ref={customerRef}
                    width="15vw"
                   />
     
                }
                {
                    currentUser.IsAdmin && 
                    <SelectControl 
                    label='Customer'
                    optionValue={customer}
                    setOptionValue={handleSelectCustomer}
                    ref={customerRef}
                    isClearable={true}
                    hiddenLabel
                  
                  />
                }
                </TitleItem>
                </CustomerTitleLine>
                </CustomerTitle>
                    <Options /> 
                    <UserIconDiv onClick={handleShowMenu} width="200px">
                    {showMenu ? <DropdownMenu handleSignout={handleSignout} setShowMenu={setShowMenu} changePass={currentUser.ChangePassword}/> : null }
                    
                    {
                        showMenu 
                        ? <Rotate><FontAwesomeIconStyled icon={faBars} /></Rotate>
                        : <UnRotate><FontAwesomeIconStyled2 icon={faBars} /></UnRotate>
                    }
                    
                    </UserIconDiv>
                    <Ticker />
                    </HeaderDiv>
                </>
                )
            
        }
        </HeaderContainer>
    )
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentCustomer: selectCurrentCustomer,
    groupCustomers: selectGroupCustomers,

});

const mapDispatchToProps = dispatch => ({
    signoutStart: (userId, auth) => dispatch(signoutStart(userId, auth)),
    clearPosts: () => dispatch(clearPostsState()),
    clearDeals: () => dispatch(clearDealsState()),
    handleGetGroupCustomers: (customerId)=> dispatch(handleGetGroupCustomers(customerId)),
    handleSetCurrentCustomer: (customer) => dispatch(setCurrentCustomer(customer)),


})

export default connect(mapStateToProps,mapDispatchToProps)(Header);