import styled from 'styled-components';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

export const Image = styled.img`
    height: 40px;
    margin-left: 10px;
    margin-top: 10px; 
    align-self: flex-end;
    flex: 1;
    /* border:1px solid black; */
`
export const BigText = styled.div`
    color: #011a59;
    font-size:50px;
    text-decoration: none;
    /* margin-left: 20px; */
    /* border: 1px solid black; */
`

export const UserIconDiv = styled.div`
        display: none;

        height: 40px;
        width: 120px;// ${props => props.width ? props.width : '120px'};
        /* background-color: white; */
        flex-Direction: column;
        justify-content: space-around;
        align-items: center;
        /* margin-top: 5px; */
        /* margin-right: 25px; */
        background-color: #0b0d14;
        cursor: ${props => props.onClick ? 'pointer' : 'normal'};
        padding: 0;
        border: 1px solid #9db2bd; 
        border-radius: 5px;
        @media screen and (max-width: 800px){
            height: 40px;
            display: flex;
        }
`
export const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
    font-size: 20px;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`;

export const FontAwesomeIconStyled2 = styled(FontAwesomeIcon)`
    font-size: 20px;
    transform: rotate(90deg);
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
`;

export const Rotate = styled.div`
    animation: rotation 0.2s  ;
    animation-fill-mode: forwards;
    @keyframes rotation {
       100% {transform: rotate(90deg)}
    }

`
export const UnRotate = styled.div`
    animation: rotation 0.2s  ;
    animation-fill-mode: forwards;
    @keyframes rotation {
       100% {transform: rotate(90deg)}
    }

`
export const OptionsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 2;
    /* margin: 0 10px; */
    /* margin-left: 10px; */
    border: 1px solid #4f4f4f;
    border-left: none;
    /* border-radius: 5px; */
    height: 60px;
    /* width: 100%; */
    background-color: #0b0d14;
    @media screen and (max-width: 800px) {
        display: none;
    }
`
export const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 0 10px; */
    justify-content: space-between;
    height:100%;
    /* border: 1px solid red; */
    flex: 2;
`;

export const HeaderContainer = styled.div`
    background-color: black;
    border-bottom: 1px solid #4d4d4d;
    height: 60px;
    position: sticky;
    top: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    ${BigText}:hover {
        font-weight: bolder;
        transform: scale(1.1);
        cursor: pointer;
    }
    ${UserIconDiv}:hover{
        color: lightsalmon;
        background-color: black;
    }

    @media screen and (max-width: 1024px) {
        height: 50px;
        box-shadow: none;
    }
`

export const CustomerTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    /* width: ${props => props.width ? props.width : '100px'}; */
    border: 1px solid #4f4f4f; 
    /* border-radius: 5px; */
    flex: 1;
    background-color: #0b0d14;
    margin-left: 10px;
    @media screen and (max-width: 800px) {
        height: 50px;
    }
`;

export const CustomerTitleLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50%;
    font-size: 12px;
    /* padding-left: 5px; */
    /* border:1px solid red; */
    flex-wrap: nowrap;
    border-bottom: ${props => props.border ? '1px solid #4f4f4f' : 'none'};
    
    `;

export const TitleItem = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: ${props => props.fixed ? '60px': 'unset'};
    /* width: ${props=> props.width ? props.width : '50%'}; */
    clear: right;
    /* border:1px solid red; */
    margin-right: 5px;
    color: ${props => props.color ? props.color : 'none'};
    font-weight: ${props => props.fweight ? props.fweight : 'unset'};
    clear: both;
    white-space: nowrap;
    flex: ${props => props.flex};
    /* min-width: 60px; */
    &:first-child {
        margin-left: 10px;
    }
    &:last-child {
        margin-right: 10px;
    }
`;

