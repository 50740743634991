import styled from 'styled-components';


export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    /* margin-top: 10px; */
    width: 100%;
    border-top: 1px solid #4d4d4d;
    height: 30px;
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* background-color: #0b0d14; */
    background-color: black;
    @media screen and (max-width: 600px) {
        visibility: hidden;
    }
`;

export const Icons = styled.div`
    font-size: 14px;
    margin: 0;
    & a {
        padding: 0 2px;
        color: #d1d4dc;
        font-weight: 600;
        text-decoration: none;
    }
    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
`;

export const P = styled.p`
    font-size: 12px;
    /* font-weight: bolder; */
    margin: 0;
    & a {
        padding: 0 2px;
        color: #947205;
        font-weight: 600;
        text-decoration: none;
    }

    @media screen and (max-width: 800px) {
        font-size: 10px;
    }
`;