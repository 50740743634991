import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


export const NTHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px dotted #4f4f4f;
    /* border-bottom: unset; */
    height: 26px;
    color: lightsalmon;
    background-color: black;
    cursor: pointer;
    /* opacity: 0.6; */
    &:hover {
        color: black;
        background-color: lightsalmon;
    }
`;

export const FontAwesomeIcn = styled(FontAwesomeIcon)`
    /* color: #9db2bd; */

`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    justify-content: space-between;
    border-left: 1px solid ;
    /* border: 1px solid pink; */
    bottom: 0;
    left: 0;
    width: 180px;
    /* border-bottom: unset; */
    height: 30px;
    color: lightsalmon;
    padding: 0 10px;
    background-color: black;
    /* opacity: 0.6; */
    font-weight: 600;
    cursor: pointer;
    &:hover {
        color: black;
        background-color: lightsalmon;
    }
`;

export const NTFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-top: 1px dotted #4f4f4f;
    /* border-bottom: unset; */
    height: 26px;
    color: lightsalmon;
    background-color: black;
    cursor: pointer;
    /* opacity: 0.6; */
    &:hover {
        color: black;
        background-color: lightsalmon;
    }

`;

export const TickerContainer = styled.div`
    /* border:1px dotted #4f4f4f; */
    width: 200px;
    height: ${props => props.showTicker ? '340px' : '30px'};
    text-align: center;
    position: fixed;
    right: ${props => props.showTicker ? '0px' : '-150px'};
    bottom: 0px;
    /* border: 1px solid white; */
    transition: right 0.8s;
    @media screen and (max-width: 800px) {
        visibility: hidden; 
    }
    &:hover {
        right: 0px;
    }
/* max-width: 620px; */
/* margin: auto; */
& i {
    font-size: 36px;
    margin: 8px;
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

& li {
      /* color: #fbe18a; */
      background: #131722;
      overflow: hidden;
      height: 50px;
      /* padding: 10px; */
      /* line-height: 30px; */
      list-style: none;
      font-size: 14px;
      width: 200px;
      text-align: center;
      border-bottom: 1px dotted #4f4f4f;
      cursor: pointer;
      margin-left: 0;
      &:hover {
          background-color: #1c2131;
      }
    }

& ul {
    list-style-type: none;
    padding-inline-start: unset;
    margin-block-start: unset;
    margin-block-end: unset;
}
`;

export const ItemTime = styled.div`
    margin-right: 20px;
     color: #bf0b00;
`;

export const ItemTitle = styled.div`
    margin-right: 20px;
    font-size: 14px;
`;

export const NewsItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 50px;
    width: 100%;
    font-size: 11px;
    /* border: 1px dotted #fbe18a; */
    cursor: pointer;
    &:hover {
        ${ItemTime} {
            color: red;
        }
        ${ItemTitle} {
            color: lightsalmon;
            /* font-weight: 600; */
        }
    }
`;