import styled, { css } from 'styled-components';

const buttonStyles = css`
        background-color: #9db2bd;
        border-radius: 5px;
        color: black;
        border: 1px solid black;
        &:hover {
            background-color: black;
            color: #9db2bd;
            border: 1px solid #9db2bd;
            font-weight: bolder;
          }
`;

const invertedButtonStyle = css`
        background-color: black;
        color: #9db2bd;
        border: 1px solid #9db2bd;
        border-radius: 5px;

        &:hover {
        background-color: #9db2bd;
        color: black;
        border: 1px solid black;
        font-weight: bolder;
        }
`;

const deleteButtonStyles = css`
        background-color: white;
        color: red;
        border: 1px solid red;
        border-radius: 5px;

        &:hover {
        background-color: red;
        color: white;
        }
`;

const GoogleSignInStyles = css`
        background-color: #4285f4;
        color: white;

        &:hover {
        background-color: white;
        color: #4285f4;
        border: 1px solid #4285f4;
        }
`;

const getButtonStyles = props => {
    if(props.isGoogleSignIn) {
        return GoogleSignInStyles;
    }
    if(props.deleteButton) {
        return deleteButtonStyles;
    }

    return props.inverted ? invertedButtonStyle : buttonStyles;
}

export const CustomButtonContainer = styled.button`
    min-width: 100px;
    width: auto;
    height: ${props => props.small ? '22px' : '30px' };
    letter-spacing: 0.5px;
    /* line-height: 30px; */
    padding: 0 5px 0 5px;
    font-size:${props => props.small ? '12px' : '15px' };
    /* text-transform: uppercase; */
    /* font-weight: bold; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
    ${getButtonStyles}

    `;

