import React from 'react';
import { connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import { sendPostRequest } from '../../api/api';
import { selectUserAuth } from '../../redux/user/user.selectors';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import './select.styles.scss'

const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={faAngleDown}/>
        </components.DropdownIndicator>
      )
    );
  }
  
  const SelectControl = React.forwardRef(({ auth, optionValue, setOptionValue, options, label, hiddenLabel, id }, ref) => {
      const styles = {
          option: (provided, state) => ({
              ...provided,
              color: state.isFocused ? 'black' : 'lightsalmon',
              backgroundColor: state.isFocused ? 'lightsalmon' : 'black',
              alignSelf: 'right',
              width: '100%',
              maxHeight: 30,
              borderBottom: '1px dotted lightsalmon',
              
          }),
          
          singleValue: (provided) => ({
              ...provided,
              color: 'lightsalmon',
              fontSize: '12px',
              borderRadius: '3px',
          }),
          control: (provided, state) => ({
              ...provided,
              width: '200px',
              backgroundColor: 'black',
              color: '#9db2bd',
              borderRadius: '3px',
              boxShadow: 'none',
              '&:hover': {
                  boxShadow: 'none'
              } ,
            //   border: 'unset',
              border: '1px solid lightsalmon',
              height: 22,
              minHeight:22,
              fontWeight: 'normal',
              marginBottom: 0,
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
          }),
          input: (provided, state) => ({
              ...provided,
              marginRight: 0,
              height: 22,
              color: 'lightsalmon',
              borderRadius: '3px',
    
    
          }),
          menu: (provided,state) => ({
              ...provided,
              width: '100%',
              borderRadius: 0,
              textAlign: 'left',
              marginTop: '0px',
              backgroundColor: 'black',
              color: 'lightsalmon',
              border: '1px solid lightsalmon'
          }),
          valueContainer: (provided,state) => ({
              ...provided,
              padding: '0px',
              right: 0,
              top: 0,
              height: '24px',
              marginTop: '0px',
              color: 'lightsalmon',
    
          }),
          indicatorsContainer: (provided)=>({
              ...provided,
              height: '100%',
              alignItems: 'center',
              marginTop: '0px',
              paddingTop: '0px',
              color: 'black',
          }),
          dropdownIndicator: (provided, state) => ({
              ...provided,
              transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
              padding: 0,
          }),
          placeholder: (provided, state)=> ({
              ...provided,
              color: 'black',
    
          }),
          menuPortal: (provided) => ({
            ...provided,
            color: 'black',
          }),
          
      }
      const loadOptions = (inputValue) => {
        return sendPostRequest('/admin/search/customer',{ searchTerm: inputValue },{ authorization: auth })
        .then(data => data)
    };

    return(
            options 
        ?   
            <div style={{position: 'relative'}}>
            <div className='select-customer-label'>{ !hiddenLabel ? label : '' }</div>
            <Select 
            id={id}
            components={{DropdownIndicator, IndicatorSeparator: ()=> null}}
            options={options}
            onChange={setOptionValue}
            styles={styles}
            isClearable={true}
            placeholder= {label}
            ref={ref}
            />
            </div>
        :      
            <div style={{position: 'relative'}}>
            <div className='select-customer-label'>{ !hiddenLabel ? label : '' }</div>
            <AsyncSelect 
            value={optionValue}
            components={{DropdownIndicator, IndicatorSeparator: ()=> null}}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.value}
            loadOptions={loadOptions}
            onChange={setOptionValue}
            styles={styles}
            ref={ref}
            isClearable={true}
            placeholder= 'Customer'
            noOptionsMessage={()=> 'Search...'}
            isSearchable={ optionValue ? (!optionValue.label ? true : false) : true }
            />
        </div>
    )
});

const mapStateToProps = createStructuredSelector({
    auth: selectUserAuth
});
export default connect(mapStateToProps,null,null,{forwardRef: true})(SelectControl);



export const SelectLocal = React.forwardRef((props, ref)=> {
    const { setOptionValue, options, label, hiddenLabel ,isClearable, width } = props;
    const styles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#9db2bd' : 'black',
            backgroundColor: state.isFocused ? 'black' : '#9db2bd',
            alignSelf: 'right',
            width: '100%',
            maxHeight: 30,
            borderBottom: '1px dotted gray',
            
        }),
        
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            fontSize: '12px',
        }),
        control: (provided, state) => ({
            ...provided,
            width: width || '162px',
            backgroundColor: '#9db2bd',
            borderRadius: '3px',
            boxShadow: 'none',
            border: 'unset',
            height: 22,
            minHeight:22,
            fontWeight: 'normal',
            marginBottom: 0,
            padding: 0,
            paddingLeft: 10,
            paddingRight: 10,
        }),
        input: (provided, state) => ({
            ...provided,
            marginRight: 0,
            height: 22,


        }),
        menu: (provided) => ({
            ...provided,
            minWidth: 'auto',
            borderRadius: 0,
            textAlign: 'left',
            marginTop: '0px',
            backgroundColor: '#9db2bd'
        }),
        valueContainer: (provided,state) => ({
            ...provided,
            padding: '0px',
            right: 0,
            top: 0,
            height: '24px',
            marginTop: '0px',

        }),
        indicatorsContainer: (provided)=>({
            ...provided,
            height: '100%',
            alignItems: 'center',
            marginTop: '0px',
            paddingTop: '0px',
            color: 'black',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            padding: 0,
        }),
        placeholder: (provided, state)=> ({
            ...provided,
            color: 'black',

        })
        
    }
    

   return(
    <div style={{position: 'relative'}}>
    <div className='select-customer-label'>{ !hiddenLabel ? label : '' }</div>
    <Select 
        components={{DropdownIndicator, IndicatorSeparator: ()=> null}}
        options={options}
        onChange={setOptionValue}
        styles={styles}
        isClearable={isClearable}
        placeholder= {label}
        ref={ref}

    />
    </div>
   ) 
});