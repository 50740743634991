import styled, {css} from 'styled-components';
import Background from './f_background.jpg';

export const MainLoginContainer = styled.div`
    position: fixed;
    top: 61px;
    bottom: 31px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 1px solid pink; */
`;
export const ParentContainer = styled.div`
    position: fixed;
    top: 60px;
    bottom: 31px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 1px solid pink; */
`;
export const Iframe = styled.div`
    /* &iframe {

        border: none !important;
        height: 100px;
    } */
`;

export const DivContainer = styled.div`
    display: flex;
    width: 100%;
    /* height: auto; */
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
    /* border: 1px solid red; */
    /* justify-content: center; */
    & a {
        padding: 0 5px;
        color: #947205;
    }
    `

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    color: black;
    width: 100vw;
    height: 25px;
    padding: 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid black ;
    box-shadow: 0px 10px 10px grey ;
`;
    
export const ErrorDisplay = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: red;
    margin-top: 25px;
`    
export const Spacer = styled.div`
    margin-bottom: 25px;
    `;

export const BrowsersDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:20vw;
    /* background-color: lightgreen; */
`;   
 export const BackgroundImg = css`
    // width: 100%;
    height:100%;    
    background-image: url(${Background});
    background-position: center;
    background-size: cover;
    content: '';
    // opacity: 0.3;    
    z-index: -1;
    `;

export const LoginPageContainer = styled.div`
        position: relative;
        overflow: hidden;
        background-size: cover;
        border: 1px solid #4f4f4f;
        text-align: center;
        margin-top: 5vh;
        width: 30vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 25px;
        border-radius: 5px;
        & img {
            position: absolute;
            border: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            z-index: -1;
            background-image: url(${Background});
            background-size: cover;

        }
        
        @media screen and (max-width: 1024px) {
            width: 80%;
        }
`;

export const Disclaimer = styled.div`
        position: relative;
        overflow: hidden;
        background-size: cover;
        border: 1px solid #4f4f4f;
        text-align: center;
        margin-top: 5vh;
        width: 30vw;
        /* ${props => props.isOpen ? '50vw' : '30vw'}; */
        height: ${props => props.isOpen ? '50vh' : '15vh'};
        display: flex;
        font-size: ${props => props.isOpen ? '18px' : '35px'  };
        flex-direction: column;
        justify-content: ${props => props.isOpen ? 'flex-start': 'center'};
        align-items: center;
        color: ${props => props.isOpen ? 'wheat' : 'unset'};
        padding-bottom: 25px;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 10px;
        overflow-y: ${props => props.isOpen ? 'auto' : 'hidden'};
        /* padding-top: ${props => props.isOpen ? '10px':'unset'}; */
        transition: width 0.5s, height 0.5s, font-size 0.5s;
        & img {
            position: absolute;
            border: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            z-index: -1;
            background-image: url(${Background});
            background-size: cover;
        }
        & span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 30px;
            border-bottom: ${props => props.isOpen ? '1px solid #4f4f4f' : 'none'};
            background-color: ${props => props.isOpen ? 'black' : 'unset'};
            /* transition: width 0.5s, height 0.5s, border-bottom 0.5s, background-color 0.5s; */
        }
        & h5 {
            display: ${props => props.isOpen ? 'flex' : 'none'};
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px;
            margin: 5px;
            width: 100%;
            border-bottom: 1px solid #4f4f4f;
        };
        & p {
            display: ${props => props.isOpen ? 'inline' : 'none'};
            text-align: justify;
            margin-top: 10px;
            padding-left: 20px;            
            padding-right: 20px;            
            padding-bottom: 10px;            
            border-bottom: ${props => props.isOpen ? '1px solid #4f4f4f': 'none'};
            font-size: ${props => props.fsize ? props.fsize : '16px'};
            /* width: 100%; */
        };
        & ol {
            display: ${props => props.isOpen ? 'block' : 'none'};
            text-align: justify;
            width: 28vw;
            font-size: 14px;
          & li {
              padding-bottom: 5px;
              width: 90%;
          }  
        };
        @media screen and (max-width: 1024px) {
            width: 80%;
            & ol{
                width: 95%;
            }
        }
        &:hover {
            & img {
                transform: ${props => props.isOpen ? 'none' :'scale(1.1)'};
                transition: transform 3s;
            }
        }
`;

export const FormContainer = styled.div`
    width: 90%;
`