import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, MenuItem, IconDiv, Seperator, H4 } from './dropdown-menu.styles';
import { faKey, faDoorOpen, faHome, faDatabase, faChartLine, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

const DropdownMenu = ({ handleSignout, setShowMenu, changePass }) => {
    let history = useHistory();
    const handleRedirect = (path) => {
        if(changePass) {
            history.push('/live-rates');
            setShowMenu();
        }else {
            history.push(path);
            setShowMenu();
        }
    };

    const handleExit = () =>{
        setShowMenu();
        handleSignout();
    }

    const { pathname } = history.location;
    
    return (
                <Dropdown >
                    <MenuItem onClick={()=>handleRedirect('/dashboard')} pathname={pathname} loc='/dashboard'>
                    <IconDiv><FontAwesomeIcon icon={faHome} /></IconDiv>
                    <H4>Dashboard</H4>
                    </MenuItem>
                <Seperator />
                    <MenuItem onClick={()=>handleRedirect('/deals')} pathname={pathname} loc='/deals'>
                    <IconDiv><FontAwesomeIcon icon={faDatabase} /></IconDiv>
                    <H4>Explore Deals</H4>
                    </MenuItem>
                <Seperator />
                    <MenuItem onClick={()=>handleRedirect('/live-rates')} pathname={pathname} loc='/live-rates'>
                    <IconDiv><FontAwesomeIcon icon={faChartLine} /></IconDiv>
                    <H4>View Live Rates</H4>
                    </MenuItem>
                <Seperator/>
                <MenuItem onClick={()=>handleRedirect('/finance/info')} pathname={pathname} loc='/finance/info'>
                <IconDiv><FontAwesomeIcon icon={faChartBar} /></IconDiv>
                <H4>Financial Information</H4>
                </MenuItem>
            <Seperator/>
                <MenuItem onClick={()=>handleRedirect('/profile/password')} pathname={pathname} loc='/profile/password'>
                <IconDiv><FontAwesomeIcon icon={faKey} /></IconDiv>
                <H4>Change Password</H4>
                </MenuItem>
            <Seperator/>
                    <MenuItem onClick={handleExit} pathname={pathname} loc={'/'}>
                    <IconDiv><FontAwesomeIcon icon={faDoorOpen}  /></IconDiv>
                    <H4>Exit System</H4>
                    </MenuItem>
        </Dropdown>
    )
}

export default DropdownMenu;