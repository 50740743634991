import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

import './form-input.styles.scss';

const FormInput = ({handleChange, label, ...otherProps}) => {
    const [type, setType] = useState(otherProps.type)
    const [icon, setIcon] = useState(faEyeSlash);
    const toggleShowPassword = ()=>{
        if(type === 'password'){
            setIcon(faEye);
            setType('text');
        }
        if(type==='text'){
            setIcon(faEyeSlash)
            setType('password');
        }
    }
    return(
    <div>
    {
    type !== 'textarea' &&  (   
    <div className='group'>
         <input className='form-input'
                onChange={handleChange} {...otherProps} type={type}  />
         {
             label ? 
             (<label htmlFor={label} className={`${otherProps.value.length  ? 'shrink' : ''} form-input-label`}>
             {label}
             </label>)              
             : null 
         }
         {
             otherProps.type === 'password' && otherProps.value.length > 0
             ? (<FontAwesomeIcon icon={icon} onMouseOver={toggleShowPassword} onMouseOut={toggleShowPassword} className='show-password'/>)
             : null
         }
         
              
         </div>)
        }
    {
        type === 'textarea' && (
        <div className='group'>
        <textarea className='form-input'
               onChange={handleChange} {...otherProps} type={type}  />
        {
            label ? 
            (<label htmlFor={label} className={`${otherProps.value.length  ? 'shrink' : ''} form-input-label`}>
            {label}
            </label>)              
            : null 
        }
        {
            otherProps.type === 'password' && otherProps.value.length > 0
            ? (<FontAwesomeIcon icon={icon} onMouseOver={toggleShowPassword} onMouseOut={toggleShowPassword} className='show-password'/>)
            : null
        }
        
             
   </div>)
    }
    </div>    


)};

export default FormInput;