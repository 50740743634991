import React, {useEffect} from 'react';

// import CustomButton from '../custom-button/custom-button.component';

import { PostDetails, Overlay, PostTitle,PostBody, RightTitlePane, LeftTitlePane, FaIcon } from './post-modal.styles';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PostModal = ({post, handleCloseModal}) => {

    const linkify = (inputText) => {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;
    
        //URLs starting with http://, https://, or ftp://
        // eslint-disable-next-line no-useless-escape
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<br /><br /><a id="post-a" href="$1" target="_blank">$1</a>');
    
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
         // eslint-disable-next-line no-useless-escape
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<br /><br /><a id="post-a" href="http://$2" target="_blank">$2</a>');
    
        //Change email addresses to mailto:: links.
         // eslint-disable-next-line no-useless-escape
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<br /><br /><a id="post-a" href="mailto:$1">$1</a>');

        replacedText = replacedText.replace('\n','<br>');
        replacedText = replacedText.replace(',',',<br>');
        replacedText = replacedText.replace('$:','<span style="color: lightgreen">$: </span>');
        replacedText = replacedText.replace('€:','<span style="color: lightgreen">€: </span>');
        replacedText = replacedText.replace('£:','<span style="color: lightgreen">£: </span>');
        replacedText = replacedText.replace('€/$:','<span style="color: lightgreen">€/$: </span>');


    
        return replacedText;
    }

    useEffect(()=> {
        const body = document.getElementById('post-body');
        body.innerHTML = linkify(post.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(post) {
            window.addEventListener('click', handleCloseModal)
        } 
        return ()=> {
            if(post) {
                window.removeEventListener('click', handleCloseModal)
            }
        }
    },[post, handleCloseModal])

    return(
        <Overlay>
        

            <PostDetails id='post'>
                <PostTitle id='post-title'>
                <RightTitlePane id='post-sub-title4'>
                <p id='post-sub-title'>{post.title}</p>
                <span id='post-sub-title1'>
                {
                    new Date(post.createdAt).toLocaleTimeString('en-gb', {timeZone: 'UTC'}).substr(0,5)
                }
                <div style={{width: '3px'}} id='post-sub-title2'/> 
                {
                    new Date(post.createdAt).toLocaleDateString('en-gb', {timeZone: 'UTC'})
                }
                </span>
                </RightTitlePane>
                <LeftTitlePane id='post-sub-title3'>
                    <FaIcon icon={faTimesCircle} onClick={handleCloseModal} />
                </LeftTitlePane>
                </PostTitle>
                <PostBody id='post-body-container'><p id="post-body"></p></PostBody>
                
            </PostDetails>

        </Overlay>
    );

};

export default PostModal;