import { sendGetRequest } from '../../api/api';
import postsActionTypes from './posts.types';

export const startGetPosts = () => {
    return async (dispatch, getState) => {
        const auth = getState().user.currentUser.auth;
        try {
            dispatch({type: postsActionTypes.GET_POSTS_START});
            const posts = await sendGetRequest('/customer/posts', {}, {authorization: auth});

            if(!posts) {
                throw new Error('כשלון בשליפת הנתונים');
            }
            dispatch({type: postsActionTypes.GET_POSTS_SUCCESS, payload: posts})
            return 'done';
        } catch(error) {
            dispatch({type: postsActionTypes.GET_POSTS_FAILURE, payload: error.message});
        }
    };
};

export const clearPostsState = ()=>({
    type: postsActionTypes.CLEAR_POSTS_STATE
});


export const clearPostsError = () =>({
    type: postsActionTypes.CLEAR_POSTS_ERROR
});