import React  from 'react';


import { OptionsLinks, Option, OptionRight} from './navbar.styles';

const NavBar = ({currentPath, handleSelectOption, handleSignout, ChangePassword }) => {

    return (

        <OptionsLinks>
        {
            !ChangePassword &&
            <>
            <Option onClick={()=> handleSelectOption('/dashboard')} active={currentPath === '/dashboard'}>Dashboard</Option>
            <Option onClick={()=> handleSelectOption('/deals')} active={currentPath === '/deals'}>Explore Deals</Option>
            <Option onClick={()=> handleSelectOption('/live-rates')} active={currentPath === '/live-rates'}>Live Rates</Option>
            <Option onClick={()=> handleSelectOption('/finance/info')} active={currentPath === '/finance/info'}>Financial Information</Option>
            </>
        }
        <Option onClick={()=> handleSelectOption('/profile/password')} active={currentPath === '/profile/password'} >Change Password</Option>
        <OptionRight><Option onClick={handleSignout} >Sign Out</Option></OptionRight>
        </OptionsLinks>
    );
};

export default NavBar;