import axios from 'axios';
import dotenv from 'dotenv';
dotenv.config();


// const API_URL='http://localhost:5000'

export const sendPostRequest = async (url,data,headers) => {
    try{
        const resp = await axios.post(url,{...data},headers ? { headers:{...headers}} : '');
        return resp.data    
    } catch(err) {
        throw new Error('Error:' + err);
    }
}
export const sendGetRequest = async (url,data,headers) => {
    try{
        const resp = await axios.get(url,{data: {...data}, headers: { ...headers}});
        return resp.data    
    } catch(err) {
        throw new Error('Error:' + err);
    }
}
export const sendPutRequest = async (url,data,headers) => {
    console.log(url,data,headers)
    try{
        const resp = await axios.put(url,{data: {...data} ,headers: { ...headers}});
        return resp.data    
    } catch(err) {
        throw new Error('Error:' + err);
    }
}

export const sendDeleteRequest = async (url,data,headers) => {
    try{
        const resp = await axios.delete(url,{data:{...data}, headers:{...headers}});
        return resp.data    
    } catch(err) {
        throw new Error('Error:' + err);
    }
}