import styled from 'styled-components';

export const OptionsLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* margin-left: 50px; */
    margin-top: 13px;
    /* border-bottom: 1px solid lightsalmon; */
    flex:2;
    `;

export const Option = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 38px;
    /* border: 1px solid lightgreen; */
    color: ${props => props.active ? 'lightsalmon' : 'unset'};
    font-size: 14px;
    margin: 0px 10px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    font-weight: ${props => props.active ? 'bolder' : 'unset'};
    /* border: ${props => props.active ? '1px solid lightsalmon' : 'unset'}; */
    border-radius: 5px ;
    /* border-bottom: ${props => props.active ? '1px solid black' : 'unset'}; */
    /* border-bottom: ${props => props.active ? '1px solid lightSalmon' : 'unset'}; */
    /* z-index: 1; */

    &:hover {
        color: lightsalmon;
        /* border-bottom:1px solid red; */
    }
`;

export const OptionRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 2;
    border-bottom: unset;
    color: darkred;
    font-weight: bolder;
`;