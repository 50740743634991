import { sendPostRequest } from '../../api/api';
import dealActionTypes from './deals.types';


const parseDate = (dateObj) => {
    const date = new Date(dateObj).toLocaleDateString('en-gb', {timeZone: 'UTC'});
    return date.substr(6,4)+'-'+date.substr(3,2)+'-'+date.substr(0,2) ;
};

export const getDeals = (filters) => {
    return async (dispatch, getState) => {
        try {

            dispatch({type: dealActionTypes.GET_DEALS_START});
            const auth = getState().user.currentUser.auth;
            const customerId = getState().user.currentCustomer.id;
            const data = {...filters, customerID: customerId}
            const deals = await sendPostRequest('/customer/deals', data, {authorization: auth});
            if(!deals) {
                throw new Error('Error fetching deals');
            }
            dispatch({type: dealActionTypes.GET_DEALS_SUCCESS, payload: deals})
            return 'done';
        }catch(error) {
            dispatch({type: dealActionTypes.GET_DEALS_FAILURE, payload: error.message});
        };
    };
};

export const getFutureVolumes = () => {
    return async (dispatch, getState) => {
        try {

            dispatch({type: dealActionTypes.GET_FUTURE_VOLUMES_START});
            const auth = getState().user.currentUser.auth;
            const customerId = getState().user.currentCustomer.id;
            const fromdate = parseDate(new Date());
            const todate = parseDate(new Date(2099,12));
            const data = { fromdate: fromdate, todate: todate, customerID: customerId}

            const volumes = await sendPostRequest('/customer/volumes', data, {authorization: auth});
            if(!volumes) {
                throw new Error('Error fetching deals');
            }
            dispatch({type: dealActionTypes.GET_FUTURE_VOLUMES_SUCCESS, payload: volumes})
            return 'done';
        }catch(error) {
            dispatch({type: dealActionTypes.GET_FUTURE_VOLUMES_FAILURE, payload: error.message});
        };
    };
};


export const clearDealsState = ()=> ({
    type: dealActionTypes.CLEAR_DEALS_STATE
});

export const clearFutureVolumes = () => ({
    type: dealActionTypes.CLEAR_FUTURE_VOLUMES
});

export const clearDealsError = ()=> ({
    type: dealActionTypes.CLEAR_DEALS_ERROR
});
