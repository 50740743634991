const userActionTypes = {
    SIGN_IN_START: 'SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    SET_USER_ERROR: 'SET_USER_ERROR',
    CLEAR_USER_ERROR: 'CLEAR_USER_ERROR',
    UPDATE_PASSWORD_START: 'UPDATE_PASSWORD_START',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
    GET_GROUP_CUSTOMERS_START: 'GET_GROUP_CUSTOMERS_START',
    GET_GROUP_CUSTOMERS_SUCCESS: 'GET_GROUP_CUSTOMERS_SUCCESS',
    GET_GROUP_CUSTOMERS_FAILURE: 'GET_GROUP_CUSTOMERS_FAILURE',
    SET_CURRENT_CUSTOMER: 'SET_CURRENT_CUSTOMER',
}

export default userActionTypes;