import userActionTypes from './user.types';

const INITIAL_STATE = {
    currentUser: null,
    error: null,
    isFetching: false,
    currentCustomer: null,
    groupCustomers: null
};

const userReducer = (state = INITIAL_STATE, action) => {
        switch(action.type) {
            case userActionTypes.SIGN_IN_START:
            case userActionTypes.SIGN_OUT_START:
            case userActionTypes.UPDATE_PASSWORD_START:
            return{
                ...state,
                error: null,
                isFetching: true

            }
        case userActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                currentCustomer: {id: action.payload.CustomerNumber, name: action.payload.CustomerName},
                isFetching: false
            }
        case userActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                currentUser: null,
                error: null,
                isFetching: false,
                currentCustomer: null,
                groupCustomers: null
            }
        case userActionTypes.SIGN_IN_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case userActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case userActionTypes.SET_USER_ERROR:
        return {
            ...state,
            error: action.payload,
            isFetching: false
        }
        case userActionTypes.CLEAR_USER_ERROR:
            return {
                ...state,
                error: null,
                isFetching: false
            }
        case userActionTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                currentUser: {...state.currentUser,...action.payload},
                error:null,
                isFetching: false
            }
        case userActionTypes.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetching: false
            }
        case userActionTypes.GET_GROUP_CUSTOMERS_START:
            return {
                ...state,
                isFetching: true
            }
        case userActionTypes.GET_GROUP_CUSTOMERS_SUCCESS:
            return {
                ...state,
                groupCustomers: action.payload,
                isFetching: false
            }
        case userActionTypes.GET_GROUP_CUSTOMERS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userActionTypes.SET_CURRENT_CUSTOMER:
            return {
                ...state,
                currentCustomer: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;