import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PostDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 30vw;
    height: 40vh;
    background-color: #0b0d14;
    border: 1px solid #4f4f4f;
    opacity: 1;
    padding-bottom: 10px;
    border-radius: 5px;
`;

export const PostTitle = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    min-height: 80px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #4f4f4f;
    background-color: #000000;
    span {
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: flex-start;    
        font-size: 14px;
        color: red;
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: 5px;
        text-align: right;
    }
    p {
        font-size : 20px;
        margin-top: 5px;
        margin-bottom: 0px;
        margin-right: 10px;
        padding: 0px 0px;
    }
`;

export const LeftTitlePane = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    width: 10%;
    height: 100%;
    background-color: black;
    border-radius: 5px 5px 0px 0px;

`;

export const RightTitlePane = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    min-height: 60px;
    border-radius: 5px 5px 0px 0px;
    background-color: #000000;
    width: 90%;
    span {
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: flex-start;    
        font-size: 14px;
        color: red;
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: 5px;
        text-align: right;
    }
    p {
        font-size : 20px;
        margin-top: 5px;
        margin-bottom: 0px;
        margin-right: 10px;
        padding: 0px 0px;
    }
`;

export const PostBody = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    /* border-bottom: 1px solid #4f4f4f; */
    margin-bottom: 10px;
    p {
        direction: rtl;
        margin-top: 5px;
        margin-right: 10px;
        margin-left: 10px;
        text-align: justify;
        /* width: 70%; */
        a {
            text-decoration: none;
            font-weight: 500;   
            color: lightgreen;
        }
    }

`;

export const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
`;

export const FaIcon = styled(FontAwesomeIcon)`
    margin-top: 10px;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
        color: white;
    }
`;