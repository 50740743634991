import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NewsTicker from 'react-advanced-news-ticker';
import { faArrowAltCircleUp, faArrowAltCircleDown, faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { startGetPosts, clearPostsState } from '../../redux/posts/posts.actions';
import { selectPosts, selectPostsError, selectPostsIsFetching } from '../../redux/posts/posts.selectors';
import Spinner from '../spinner/spinner.component';
import PostModal from '../post-modal/post-modal.component';

import { NewsItem, TickerContainer, NTHeader, NTFooter, Header, FontAwesomeIcn, ItemTime, ItemTitle } from './news-ticker.styles';


const Ticker = ({getPosts, posts, isFetching, error, clearPosts})=> {
    const persistedShowTicker = JSON.parse(localStorage.getItem('showNews')).showTicker;

    const [showTicker, setShowTicker] = useState(persistedShowTicker);
    const [showPost, setShowPost] = useState(null);
    const ref = useRef(null)

    useEffect(()=> {
        if(showTicker){
            getPosts();
        }

        return () => {
            if(showTicker){
             clearPosts();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showTicker]);

    const handleNewsWindow = () => {
        setShowTicker(prevState => !prevState);
        localStorage.setItem('showNews', JSON.stringify({showTicker: !showTicker}));
    };
    
    
    
    
    const handleShowPost = (post) => {
        setShowPost(post);
    };
    
    const handleHidePost = useCallback((e)=> {
        const hidePost = !e.target.id.includes('post')
        if(hidePost){
            setShowPost(null);
        }

    },[setShowPost])
    
    const Icon = ()=> showTicker ? <FontAwesomeIcn icon={faFolderOpen} /> : <FontAwesomeIcn icon={faFolder} />;
    const Head = ()=> showTicker ? 
                <Header onClick={handleNewsWindow}><div><Icon /></div><div>News</div></Header> 
                               : 
                <Header onClick={handleNewsWindow}><div><Icon /></div><div>Display News</div></Header>;
    

    return (
        <TickerContainer showTicker={showTicker}>

        
        {
            isFetching && (<div style={{border: '1px solid #4f4f4f', height: '310px', position: 'fixed',bottom: '30px', width: '200px'}}><Spinner /></div>)
        }
        {
            !isFetching && showTicker && (
                
                <div style={{border: '1px solid #4f4f4f', position: 'fixed',bottom: '30px'}}>
                <NTHeader onClick={()=>{ref.current.movePrev(); ref.current.resetInterval();}}><FontAwesomeIcn icon={faArrowAltCircleUp}/></NTHeader>
                <NewsTicker 
                rowHeight={50}
                maxRows={5}
                direction={1}
                duration={3000}
                autoStart={true}
                pauseOnHover={true}
                id="ticker1"
                ref={ref}
                >
                {
                    posts.map(post => {
                        const postDate = new Date(post.createdAt).toLocaleDateString('en-gb', {timeZone: 'UTC'});
                        const postTime = new Date(post.createdAt).toLocaleTimeString('en-gb', {timeZone: 'UTC'}).substr(0,5);                        
                        return (
                            <NewsItem key={post.messageID} onClick={()=>handleShowPost(post)}>
                            <ItemTime>
                            {postDate + ' ' + postTime}
                            </ItemTime>
                            <ItemTitle>
                            {post.title}
                            </ItemTitle>
                            </NewsItem>
                            )
                        })
                    }
                    </NewsTicker>
                    <NTFooter onClick={()=> {ref.current.moveNext(); ref.current.resetInterval();}}><FontAwesomeIcn icon={faArrowAltCircleDown}/></NTFooter>
                    </div>   
                    )}
                    {
                        error && <div>{error}</div>
                    }
                    {showPost !== null && <PostModal post={showPost} handleCloseModal={handleHidePost} />}                
                    
                    {<Head />}
                    </TickerContainer>
                    );
                };
                
                const mapStateToProps = createStructuredSelector({
    posts: selectPosts,
    isFetching: selectPostsIsFetching,
    error: selectPostsError

});

const mapDispatchToProps = dispatch => ({
    getPosts: () => dispatch(startGetPosts()),
    clearPosts: () => dispatch(clearPostsState())
});

export default connect(mapStateToProps, mapDispatchToProps)(Ticker);