import financeInfoTypes from './finance-info.types';

const INITIAL_STATE_FINANCE = {
    articles: [],
    loading: false,
    error: null
};

const articlesReducer = (state = INITIAL_STATE_FINANCE, action) => {
    
    switch(action.type) {
    
    case financeInfoTypes.GET_LATEST_ARTICLES_START:
        return {
            ...state,
            loading: true
        }
    case financeInfoTypes.GET_LATEST_ARTICLES_SUCCESS:
        return {
            ...state,
            articles: action.payload,
            loading: false
        }
    case financeInfoTypes.GET_LATEST_ARTICLES_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    case financeInfoTypes.CLEAR_ARTICLES_ERROR:
        return {
            ...state,
            error: null
        }
    case financeInfoTypes.CLEAR_ARTICLES_STATE:
        return {
            ...state,
            articles:[],
            loading: false,
            error: null
        }
        default:
            return state;
    }
};

export default articlesReducer;