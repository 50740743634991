const dealActionTypes = {
    GET_DEALS_START: 'GET_DEALS_START',
    GET_DEALS_SUCCESS: 'GET_DEALS_SUCCESS',
    GET_DEALS_FAILURE: 'GET_DEALS_FAILURE',
    CLEAR_DEALS_STATE: 'CLEAR_DEALS_STATE',
    GET_FUTURE_VOLUMES_START: 'GET_FUTURE_VOLUMES_START',
    GET_FUTURE_VOLUMES_SUCCESS: 'GET_FUTURE_VOLUMES_SUCCESS',
    GET_FUTURE_VOLUMES_FAILURE: 'GET_FUTURE_VOLUMES_FAILURE',
    CLEAR_FUTURE_VOLUMES: 'CLEAR_FUTURE_VOLUMES',
    CLEAR_DEALS_ERROR: 'CLEAR_DEALS_ERROR'

};

export default dealActionTypes;