import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { LoginPageContainer, 
        DivContainer, 
        FormContainer, 
        ErrorDisplay, 
        Spacer, 
        BrowsersDiv,
        MainLoginContainer,
        // BackgroundImg,
        Disclaimer,
        ParentContainer
    } from './login.styles';
import FormInput from "../form-input/form-input.component";
import CustomButton from '../custom-button/custom-button.component';

import { signInStart, clearUserError } from '../../redux/user/user.actions';
import { selectUserError } from '../../redux/user/user.selectors';
import Background from './f_background.jpg';


const LoginPage = ({ signInStart, error, clearUserError })=> {
    const [userCredentials, setCredentials ] = useState({ email: '', password: ''});
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const { email, password } = userCredentials;
    const handleChange = event => {
        const { value, name } = event.target;
        setCredentials({...userCredentials,[name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();
        signInStart(userCredentials);
    };
    const handleClearError = () => {
        if(error){
            clearUserError();
        }
    }
    const handleOpenDisclaimer = () => {
        setDisclaimerOpen(prevState => !prevState);
    };
    return (
      <ParentContainer>

        <MainLoginContainer>
          <DivContainer>
            <h2 style={{color: 'white', marginTop: '10px', marginBottom: '0px'}}>Financial Risk Managment</h2>
            <h2 style={{margin: '0px'}}>Customers Area</h2>
            <LoginPageContainer>
              <img src={Background} alt="" />
              <p style={{ fontSize: "18px" }}>
                Please enter you credentials to log in{" "}
              </p>
              <FormContainer>
                <form onSubmit={handleSubmit}>
                  <FormInput
                    name="email"
                    type="email"
                    value={email}
                    handleChange={handleChange}
                    label="Email"
                    required
                    onFocus={handleClearError}
                  />
                  <FormInput
                    name="password"
                    type="password"
                    value={password}
                    handleChange={handleChange}
                    label="Password"
                    required
                    onFocus={handleClearError}
                  />
                  <Spacer />
                  <DivContainer>
                    <CustomButton type="submit" inverted>
                      Enter
                    </CustomButton>
                  </DivContainer>
                  {error && (
                    <ErrorDisplay>
                      <span>...הייתה בעיה בכניסה</span>
                      יש לבדוק את שם המשתמש והסיסמא
                    </ErrorDisplay>
                  )}
                </form>
              </FormContainer>
              <DivContainer></DivContainer>
            </LoginPageContainer>
            <DivContainer>
              <p>
                If you do not have an account please contact us at phone:
                <span>
                  <a href="tel:+97236167070">+972-3-6167070</a>
                </span>
              </p>
            </DivContainer>
            <DivContainer>
              <p>This site is best viewed with the following browsers:</p>
              <BrowsersDiv>
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Chrome_icon_%28September_2014%29.svg"
                    alt="chrome logo"
                    width="30px"
                    height="30px"
                  />
                </a>

                <a
                  href="https://www.mozilla.org/en-US/firefox/download/thanks/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1024px-Firefox_logo%2C_2019.svg.png"
                    alt="firefox logo"
                    width="30px"
                    height="30px"
                  />
                </a>

                <a href="https://go.microsoft.com/fwlink/?linkid=2109047&Channel=Stable&language=en&consent=1">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/he/thumb/b/b7/%D7%9C%D7%95%D7%92%D7%95_%D7%9E%D7%99%D7%A7%D7%A8%D7%95%D7%A1%D7%95%D7%A4%D7%98_%D7%90%D7%93%D7%92%27.svg/1024px-%D7%9C%D7%95%D7%92%D7%95_%D7%9E%D7%99%D7%A7%D7%A8%D7%95%D7%A1%D7%95%D7%A4%D7%98_%D7%90%D7%93%D7%92%27.svg.png"
                    alt="edge 2020 logo"
                    width="30px"
                    height="30px"
                  />
                </a>
              </BrowsersDiv>
            </DivContainer>
          </DivContainer>
          <DivContainer>
            <Disclaimer onClick={handleOpenDisclaimer} isOpen={disclaimerOpen}>
              <img src={Background} alt="" />
              <span>Disclaimer</span>
              <h5>Legal Disclaimer and Terms and Conditions</h5>
              <p>
                Prico Management Ltd. (hereinafter: “Prico”) permits you to
                access its website subject to the conditions specified below ,
                and in addition, subject to any document and/or agreement signed
                by the customer,in particular the documents “Statement of
                commitment for the receipt of information services.”
              </p>
              <ol>
                <li>
                  The information and all of the pages on the website are the
                  exclusive property of Prico.
                </li>
                <li>
                  Trademarks appearing on the website are under the proprietary
                  copyright of Prico Group; any action that may infringe on the
                  copyright of such trademarks is prohibited.
                </li>
                <li>
                  The website in its entirety, including all information
                  therein, the software on which it is based, and information
                  and materials accessed through the website, are presented and
                  made available to you as they are as of this moment.
                </li>
                <li>
                  The information on the website is subject to changes from time
                  to time, at the discretion of Prico Management, including
                  cancellation or change of plans, services, or products
                  presented on this website, without advance notice, even if not
                  yet updated on the website, and to changes according to the
                  provisions of all laws.
                </li>
                <li>
                  Any altering, copying, distributing, broadcasting, presenting,
                  executing, duplicating, publishing, or selling of any item of
                  information, software, or service originating with this
                  website is prohibited.
                </li>
                <li>
                  The statements in the pages and information do not constitute
                  a proposal, persuasion, or advice regarding credit and/or
                  investment from Prico, and cannot replace professional,
                  individual advice that takes the user’s specific information
                  into consideration.
                </li>
                <li>
                  The statements on the website do not constitute a proposal to
                  enter into a contract, or a presentation for the purposes of a
                  transaction; only the statements in documents signed by Prico
                  Management are binding for Prico.
                </li>
                <li>
                  The explanations presented on the website with regard to the
                  services of Prico and the conditions for receiving credit are
                  only partial and cannot replace perusal of the loan documents.
                  To receive the information relevant to a requested service in
                  full, please contact the representatives of Prico.
                </li>
                <li>
                  Information presented on the website may contain proofreading
                  errors, errors in phrasing, clerical errors, etc., despite the
                  fact that Prico does everything in its power to prevent such
                  occurrences. Prico is not responsible for any damage caused by
                  the presence of such errors.
                </li>
                <li>
                  Prico is not responsible for any damage that may be caused by
                  deficiencies, malfunctions, or erroneous operation of the
                  software operating the website or the access to the website.
                </li>
                <li>
                  Only a notification from Prico can serve as evidence of the
                  execution of a transaction or of the status of an account.
                </li>
                <li>
                  All computer systems, including online systems, occasionally
                  suffer malfunctions. Use of the Internet, particularly for the
                  purpose of trading, is exposed to the inherent risks of the
                  Internet and of computer systems based on software, hardware,
                  and communications networks. We undertake every effort to
                  minimize malfunctions and to allow customers to continue
                  trading at all times. However, it is impossible to entirely
                  prevent such malfunctions and risks. Prico is therefore exempt
                  from responsibility for any damage, loss, or expense
                  whatsoever, and/or disruption of information displayed on the
                  systems, that may be caused to the customer as a result of the
                  following factors, among others: temporary interruptions of
                  service through communication lines, and/or other disruptions
                  and malfunctions in communication lines, and/or disruptions in
                  the functioning or availability of the website, and/or in
                  response times of the computerized systems, and/or disruptions
                  of information and/or data and/or transfer and/or reception of
                  orders, due to malfunctions arising from communication lines,
                  or other malfunctions not under Prico's control, and/or which
                  could not have been prevented by Prico with reasonable effort.
                </li>
                <li>
                  This website may contain links to websites not operated by
                  Prico, which are operated by other parties. Prico has no
                  control over such websites and bears no responsibility for the
                  content displayed therein.
                </li>
                <li>
                  The user of the website is responsible for preventing viruses
                  from entering the system used by the user; Prico accepts no
                  responsibility in this matter. The customer must also take all
                  necessary measures to protect the password and user code that
                  allow access to the system. In the event that a customer
                  directly or indirectly provides such identifying information
                  to a third party (for example, by entering it on a website
                  other than the website of Prico), Prico is not responsible for
                  exposure of information and/or execution of transactions in
                  the customer’s account.
                </li>
                <li>
                  The website may allow downloads of software. Prico Management
                  is not responsible for any damage that may be caused by the
                  download of such software and/or by the use of any software
                  downloaded from the website.
                </li>
                <li>
                  Applications on the website may collect information about
                  users’ activity on the website and analyse such information in
                  order to improve service.
                </li>
                <li>
                  Information provided by a user on the website may be retained
                  in databases and used for the development and improvement of
                  services.
                </li>
                <li>
                  Information on the website and the use thereof are subject to
                  the laws of the State of Israel. Please note that use of,
                  reliance upon, and action according to the information may be
                  restricted or possibly illegal under the laws of various
                  countries; you must therefore determine, prior to using the
                  information, whether you are entitled to use it and the extent
                  of the permitted usage pursuant to the laws applicable to you.
                </li>
              </ol>
            </Disclaimer>
          </DivContainer>
          <div style={{ marginTop: "10px" }}>
          <iframe
            src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,5,14,20,41,1827,959&border=hide&speed=10&click_target=blank&theme=dark&flags=flags-rectangle&column=spread,chg,chg_per"
            width="100%"
            height="67"
            title="FxRates"
            style={{ border: "none", zIndex: "2" }}
          ></iframe>
          <div
            style={{
              textAlign: "center",
              fontSize: "13px",
              fontFamily: "sans-serif",
              color: "#9db2bd",
            }}
            id="fx-pricing-widget-copyright"
          >
            <span>Powered by </span>
            <a
              href="https://fxpricing.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "unset",
                color: "#bb3534",
                fontWeight: "600",
              }}
            >
              FX Pricing
            </a>
          </div>
        </div>
        </MainLoginContainer>
        
      </ParentContainer>
    );
};
const mapStateToProps = createStructuredSelector({
    error: selectUserError
})

const mapDispatchToProps = dispatch => ({
    signInStart: userCredentials => dispatch(signInStart(userCredentials)),
    clearUserError: ()=> dispatch(clearUserError())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);