import React, {Suspense, lazy} from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import LoginPage from '../components/login/login.component';
import Header from '../components/header/header.component';
import Footer from '../components/footer/footer.component';
import Spinner from '../components/spinner/spinner.component';

import {HelpPage, NotFoundPage} from '../pages/utility/utility.pages';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const DashBoard = lazy(()=> import('../pages/dashboard/dashboard.component'));
const HomePage = lazy(()=> import('../pages/home/homepage.container'));
const LiveRatesPage = lazy(()=> import('../pages/live-rates/live-rates-page.component'));
const ChangePasswordPage = lazy(()=> import('../components/change-password/change-password.component'));
const FinanceInfoPage = lazy(()=> import('../pages/financial-information/finance-info.component'));

export const history = createBrowserHistory();

const AppRouter = () => (
    <Suspense fallback={<Spinner />}>
    <Router history={history}>
    <div>
        <Header />
        <Switch>
            <PublicRoute path="/" component={LoginPage} exact={true}/>
            <PrivateRoute path="/dashboard" component={DashBoard} exact={true}/>
            <PrivateRoute path="/deals" component={HomePage} exact={true} />
            <PrivateRoute path="/live-rates" component={LiveRatesPage} exact={true}/>
            <PrivateRoute path="/profile/password" component={ChangePasswordPage} exact={true}/>
            <PrivateRoute path="/finance/info" component={FinanceInfoPage} exact={true}/>
            <Route path="/help" component={HelpPage}/>
            <Route component={NotFoundPage}/>
        </Switch>
        <Footer/>
        </div>
    </Router>
    </Suspense>
);

export default AppRouter;

