import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { signoutStart } from './redux/user/user.actions';
import { selectCurrentUser } from './redux/user/user.selectors';

import AppRouter from './routers/AppRouter';
// import Header from './components/header/header.component';
import './App.css';
function App({ signoutStart, currentUser, history }) {
  const handleSignOut = () => {
    if(currentUser) {
      const {UserID} = currentUser;
      signoutStart(UserID)
    }
  }

  useEffect(()=> {
      window.addEventListener('unload', (e)=>{
        handleSignOut();
      });
        return ()=>{ 
          window.removeEventListener('unload', (e)=>{
              handleSignOut();
          });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

   return (
      <div className='App'>
        <AppRouter />
      </div>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});
const mapDispatchToProps = dispatch => ({
  signoutStart: (userId, auth)=> dispatch(signoutStart(userId, auth))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
